import React from "react";
import style from "../styles/Events.module.css";
import Button from "../components/Button/Button";
import Timer from "../components/Timer/Timer";
import person_1 from "../assets/Events_Assets/image 53.png";
import frame_1 from "../assets/Events_Assets/Component 27.png";
import frame_2 from "../assets/Events_Assets/Component 29.png";
import frame_3 from "../assets/Events_Assets/Component 30.png";
import frame_4 from "../assets/Events_Assets/Component 28.png";
// import mobile_frame_1 from "../assets/Events_Assets/Component 43.png";
// import mobile_frame_2 from "../assets/Events_Assets/Component 44.png";
// import mobile_frame_3 from "../assets/Events_Assets/Component 46.png";
// import mobile_frame_4 from "../assets/Events_Assets/Component 47.png";
// import polygon_3 from "../assets/Events_Assets/Polygon 3(1).png";
// import polygon_4 from "../assets/Events_Assets/Polygon 5(1).png";
import Union from "../assets/Events_Assets/Union.png";
import { CiLocationOn } from "react-icons/ci";
import { BsArrowUpRight } from "react-icons/bs";
import Page from "../components/Page";
import HackathonImg from "../assets/HackathonImg.jpeg";
import { NavLink } from "react-router-dom";

const Events = () => {
	return (
		<Page>
			<section className={style.first__section}>
				<div className={style.first__section__flex}>
					<p className={style.first__section__para}>
						September 7th 2024
					</p>
					<h1>
						<span>Dapps over apps</span> Open Web Tour
					</h1>

					<p className={style.first__section__para2}>
						Learn how to build blockchain applications
						with Arbitrum Stylus & SDK. Build: Get your hands
						"locked" on-chain. Earn # 3 million hackathon prize +
						#1million bounty prize + global networking
					</p>

					<div>
						<Timer />
					</div>

					<div className={style.first__section__flex__btn}>
						<NavLink to="/gallery/hackathon">
							<div
								className={
									style.first__section__first__btn__div
								}
							>
								<button
									className={style.first__section__first__btn}
								>
									Hackathons
								</button>
							</div>
						</NavLink>
						<NavLink
							to={"/tickets"}
							className="p-4 primary-gradient rounded px-5 hover:animate-bounce "
						>
							Get Tickets
						</NavLink>
					</div>
				</div>
				<div className={style.first__section__flex__img}>
					<img src={HackathonImg} alt="" />
				</div>
			</section>

			<section className={style.second__section}>
				<div className={style.second__section__text}>
					<p className={style.second__section__first__para}>
						About the dApps over apps event
					</p>
					<h1 className={style.second__section__header}>
						Take Advantage of the opportunities presented to you by
						the <span>Web3</span>
					</h1>
					<p>
						Join the dapps over apps community and propel your
						career in the blockchain ecosystem. Go from noob to
						expert, join the community of web/app developers,
						creatives, <span>NFT traders</span>, and general masses
						moving to the blockchain.
					</p>
					<p>
						Join the dapps over apps community and propel your
						career in the blockchain ecosystem. Go from noob to
						expert, join the community of web/app developers,
						creatives, NFT traders, and general masses moving to the{" "}
						<span>blockchain</span>.
					</p>
					<p>
						Join the dapps over apps community and propel your
						career in the blockchain ecosystem. Go from noob to
						expert, join the community of web/app developers,
						creatives, NFT
					</p>
				</div>
				<div className="w-full h-full">
					<button>
						Become a Sponsor{" "}
						<BsArrowUpRight className={style.arrow__icon} />
					</button>
					<img src={person_1} alt="" />
				</div>
			</section>

			{/* Reasons to attend section */}

			<section className="text-white py-10 md:py-14">
				<p className="text-primary">Why should we see you there</p>
				<h3 className="text-3xl font-bold pb-2 md:py-3">
					Great reasons to attend the dApps over Apps conference
				</h3>
				{/* cards container */}
				<div className="flex flex-row flex-wrap gap-4 items-center justify-evenly pb-8">
					<div className="relative hover-class">
						<img src={frame_1} alt="" className="" />
						<div className="overlay-class absolute inset-0 p-4 md:p-10 bg-black backdrop-blur-lg opacity-75 flex flex-col items-center justify-center">
							<h3 className="text-2xl font-semibold pb-2 md:py-3">
								Featuring Industry Experts
							</h3>
							<div className="pb-2 md:py-3">
								The 2023 Decentralized Intelligence summit will
								feature some of the top speakers across the
								industry
							</div>
							<Button
								bgColor={
									"linear-gradient(90deg, var(--secondary-bg-color) 0%, var(--asset-bg-color) 100%)"
								}
								text="Get tickets on Eventbrite"
							/>
						</div>
					</div>
					<div className="relative hover-class">
						<img src={frame_2} alt="" />
						<div className="overlay-class absolute inset-0 p-4 md:p-10 bg-black backdrop-blur-lg opacity-75 flex flex-col items-center justify-center">
							{" "}
							<h3 className="text-2xl font-semibold pb-2 md:py-3">
								Over 50+ talks
							</h3>
							<div className="pb-2 md:py-3">
								Among other things, we shall be exploring key
								ethical considerations surrounding the use of AI
								in decentralized environments, including issues
								of bias, fairness, and accountability.
							</div>
							<Button
								bgColor={
									"linear-gradient(90deg, var(--secondary-bg-color) 0%, var(--asset-bg-color) 100%)"
								}
								text="Get tickets on Eventbrite"
							/>
						</div>{" "}
					</div>
				</div>
				<div className="flex flex-row flex-wrap gap-4 items-center justify-evenly">
					<div className="relative hover-class">
						<img src={frame_3} alt="" />
						<div className="overlay-class absolute inset-0 p-4 md:p-10 bg-black backdrop-blur-lg opacity-75 flex flex-col items-center justify-center">
							<h3 className="text-2xl font-semibold pb-2 md:py-3">
								Networking
							</h3>
							<div className="pb-2 md:py-3">
								{" "}
								We have never stopped building this ecosystem
								and this conference will be hosted on the
								strength of those early foundations. We have
								connected thousands to their destinies in the
								decentralized future. We won't stop building!
							</div>
							<Button
								bgColor={
									"linear-gradient(90deg, var(--secondary-bg-color) 0%, var(--asset-bg-color) 100%)"
								}
								text="Get tickets on Eventbrite"
							/>
						</div>
					</div>

					<div className="relative hover-class">
						<img src={frame_4} alt="" />
						<div className="overlay-class absolute inset-0 p-4 md:p-10 bg-black backdrop-blur-lg opacity-75 flex flex-col items-center justify-center">
							<h3 className="text-2xl font-semibold pb-2 md:py-3">
								Biggest Blockchain and web3 conference in Africa
							</h3>
							<div className="pb-2 md:py-3">
								{" "}
								While growing this community here in Nigeria in
								the past 7 years, we've created the largest
								gathering of crypto investors, developers, and
								industry professionals in the entire country and
								across Africa.
							</div>
							<Button
								bgColor={
									"linear-gradient(90deg, var(--secondary-bg-color) 0%, var(--asset-bg-color) 100%)"
								}
								text="Get tickets on Eventbrite"
							/>
						</div>
					</div>
				</div>
			</section>
			{/* <section className={style.third__section}>
				<p className={style.third__section__para}>
					Why should we see you there?
				</p>
				<h1 className={style.third__section__header}>
					Great reasons to attend the dApps over Apps Conference.
				</h1>
				<div className={style.angry_grid}>
					<div className={style.item_0 + " border border-red-700"}>
						<img src={frame_1} alt="" className={style.frame_1 + " border border-red-700"} />
						<div className={style.item_0_overlay}>
							<h2>Featuring Industry Experts</h2>
							<p>
								The 2023 Decentralized Intelligence Summit will
								feature some of the top speakers across the
								industry.
							</p>
							<Button
								bgColor={
									"linear-gradient(90deg, var(--secondary-bg-color) 0%, var(--asset-bg-color) 100%)"
								}
								text="Get tickets on Eventbrite"
							/>
						</div>
						<img
							src={mobile_frame_1}
							alt=""
							className={style.mobile_frame_1}
						/>
					</div>
					<div className={style.item_1 + " border border-red-700"}>
						<img src={frame_2} alt="" className={style.frame_2 + " border border-red-700"} />
						<div className={style.item_1_overlay}>
							<h2>Over 50+ talks</h2>
							<p>
								Among other things, we shall be exploring key
								ethical considerations surrounding the use of AI
								in decentralized environments, including issues
								of bias, fairness, and accountability.
							</p>
							<Button
								bgColor={
									"linear-gradient(90deg, var(--secondary-bg-color) 0%, var(--asset-bg-color) 100%)"
								}
								text="Get tickets on Eventbrite"
							/>
						</div>
						<img
							src={mobile_frame_2}
							alt=""
							className={style.mobile_frame_2}
						/>
					</div>
					<div className={style.item_2 + " border border-red-700"}>
						<img src={frame_3} alt="" className={style.frame_3 + " border border-red-700 "} />
						<div className={style.item_2_overlay}>
							<h2>Networking</h2>
							<p>
								We have never stopped building this ecosystem
								and this conference will be hosted on the
								strength of those early foundations. We have
								connected thousands to their destinies in the
								decentralized future. We won't stop building!
							</p>
							<Button
								bgColor={
									"linear-gradient(90deg, var(--secondary-bg-color) 0%, var(--asset-bg-color) 100%)"
								}
								text="Get tickets on Eventbrite"
							/>
						</div>
						<img
							src={mobile_frame_3}
							alt=""
							className={style.mobile_frame_3}
						/>
					</div>
					<div className={style.item_3 + " border border-red-700"}>
						<img src={frame_4} alt="" className={style.frame_4 + " border border-red-700"} />
						<div className={style.item_3_overlay}>
							<h2>
								Biggest Blockchain & Web3 Conference in Africa
							</h2>
							<p>
								While growing this community here in Nigeria in
								the past 7 years, we've created the largest
								gathering of crypto investors, developers, and
								industry professionals in the entire country and
								across Africa.
							</p>
							<Button
								bgColor={
									"linear-gradient(90deg, var(--secondary-bg-color) 0%, var(--asset-bg-color) 100%)"
								}
								text="Get tickets on Eventbrite"
							/>
						</div>
						<img
							src={mobile_frame_4}
							alt=""
							className={style.mobile_frame_4}
						/>
					</div>
				</div>
			</section> */}

			<section className={style.fourth__section}>
				<div className={style.angry_grid_2}>
					<img src={Union} alt="" />
				</div>
				<div>
					<p>Conference Venue</p>
					<h1>The Civic Center Royal Banquet Hall</h1>
					<p className={style.fourth__section__second__para}>
						The Civic Center is located in Victoria Island, Lagos
						Nigeria. It is one of the largest and most prestigeous
						Convention Centers in the city. It has been the venue
						for many national and international events in the past
						and offers state of art event hosting services. It has
						been the venue of all our tech conferences since 2017.
					</p>
					<div className={style.button__flex}>
						<button className={style.event__location}>
							View Event Location{" "}
							<CiLocationOn className={style.location__icon} />
						</button>
						<Button
							bgColor={
								"linear-gradient(90deg, var(--secondary-bg-color) 0%, var(--asset-bg-color) 100%)"
							}
							text="Get tickets on Eventbrite"
						/>
					</div>
				</div>
			</section>

			<section className={style.mobile__fourth__section}>
				<div>
					<p>Conference Venue</p>
					<h1>The Civic Center Royal Banquet Hall</h1>
					<p className={style.fourth__section__second__para}>
						The Civic Center is located in Victoria Island, Lagos
						Nigeria. It is one of the largest and most prestigeous
						Convention Centers in the city. It has been the venue
						for many national and international events in the past
						and offers state of art event hosting services. It has
						been the venue of all our tech conferences since 2017.
					</p>
					<div className={style.angry_grid_2}>
						<img src={Union} alt="" />
					</div>
					<div className={style.button__flex}>
						<button className={style.event__location}>
							View Event Location{" "}
							<CiLocationOn className={style.location__icon} />
						</button>
						<Button
							bgColor={
								"linear-gradient(90deg, var(--secondary-bg-color) 0%, var(--asset-bg-color) 100%)"
							}
							text="Get tickets on Eventbrite"
						/>
					</div>
				</div>
			</section>
		</Page>
	);
};

export default Events;

import React from "react";
import { BiTrash } from "react-icons/bi";

const AdminBlogCard = ({ title, image, tags }) => {
	return (
		<div className="p-3 w-[380px] shadow-xl relative">
			<div>
				<img src={image} className="w-full" alt="" />
			</div>
			<h4 className="p-2 text-lg font-semibold">{title}</h4>
			<div className="flex flex-row gap-2">
				{tags?.map((tag) => (
					<div key={tag} className="border border-primary rounded-lg p-1 px-2">
						{tag}
					</div>
				))}
			</div>
			<div className="absolute p-2 rounded-lg text-red-500 right-4 bottom-4 flex flex-row gap-3 hover:bg-red-700 hover:text-white">
				<BiTrash />
        Delete Post
			</div>
		</div>
	);
};

export default AdminBlogCard;

import { NavLink } from "react-router-dom";

const HackathonPageCard = ({ title, text1, text2, date, link, image, pending }) => {
	return (
		<div className="flex lg:flex-row flex-col gap-4 pt-4 lg:p-8 items-center justify-between">
			<div className="flex-1 bg-grayish p-8 rounded-xl">
				<div className="text-primary py-3">{date}</div>
				<h1 className="text-4xl font-bold py-4">
					{title}
					<span className="text-primary font-extrabold px-3">
						Hackathon
					</span>{" "}
					Registration
				</h1>
				<div className="pt-3 pb-1 text-lg leading-5">{text1}</div>
				<div className="pb-7 text-lg leading-5">{text2}</div>
				<NavLink
					to={link}
					className={pending? "p-2 primary-gradient rounded px-5 hover:animate-pulse cursor-none": "p-2 primary-gradient rounded px-5 hover:animate-pulse "}
				>
					Register Now
				</NavLink>
			</div>
			<div className="flex-1 py-5">
				<img src={image} alt="" className="rounded-lg" />
			</div>
		</div>
	);
};

export default HackathonPageCard;

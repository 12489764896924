import React from "react";
import { FaPlayCircle } from "react-icons/fa";

const TutorialCard = ({ image, title, level }) => {
	return (
		<div className="rounded-xl shadow-md bg-grayish cursor-pointer hover:scale-95">
			<div className="relative">
				<FaPlayCircle className="absolute left-1/2 top-1/2 h-20 w-20 translate-y-[-50%] translate-x-[-50%] opacity-70" />
				<img
					src={image}
					className="h-[180px] object-cover rounded-xl"
					alt=""
				/>
			</div>

			<div className="p-3">
				<h4 className="text-bold py-2">{title}</h4>
				<div className="border-primary border w-fit rounded-lg p-1 text-xs">
					{level}
				</div>
			</div>
		</div>
	);
};

export default TutorialCard;

import Logo from "../assets/Home_Assets/image_1-removebg-preview.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import Footer from "../components/Footer/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../utils";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { useState } from "react";

const HackathonReg = () => {
	const [loading, setloading] = useState(false);

	// submit logic
	const handleSubmit = async (values) => {
		setloading(true);

		try {
			const response = await fetch(`${BASE_URL}/individual`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(values),
			});
			const data = await response.json();
			console.log(data);
			toast("Application successful!");
			// resetForm();
		} catch (error) {
			console.error("Error submitting form:", error);
			toast.error("Failed to submit form");
		} finally {
			setloading(false);
		}
	};

	const userEmail = localStorage.getItem("email");

	// schema and configuration for form validation
	const initialValues = {
		firstName: "",
		lastName: "",
		education: "",
		role: "",
		skills: "",
		github: "",
		twitter: "",
		about: "",
		email: userEmail,
	};
	const validationSchema = Yup.object({
		firstName: Yup.string().required("Please enter first name"),
		lastName: Yup.string().required("Please enter last name"),
		education: Yup.string().required(
			"Please enter the name of your institution"
		),
		role: Yup.string().required("Please enter your role in the hackathon"),
		skills: Yup.string().required("Please enter your skills"),
		github: Yup.string(),
		twitter: Yup.string(),
		about: Yup.string()
			.required("Tell us a little about yourself")
			.min(5, "Please enter at least 5 characters"),
	});

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values, { resetForm }) => {
			console.log(values);
			localStorage.setItem("details", JSON.stringify(values));
			handleSubmit(values);
			resetForm()
		},
	});

	return (
		<motion.div
			initial={{ opacity: 0, x: 100 }}
			animate={{ opacity: 1, x: 0 }}
			exit={{ opacity: 0, x: -100 }}
			className="text-white"
		>
			{/* Display bar */}
			<div className="flex flex-row gap-3 bg-grayish items-center justify-between p-2 px-5 md:px-16">
				<div>
					<img src={Logo} alt="" className="h-12 w-12 rotate" />
				</div>
				<div className="hidden md:block">
					<h1 className="font-semibold text-xl">
						Open Web Tour Hackathon
					</h1>

					<div className="text-gray-400">Get registered here</div>
				</div>
				<div>
					<span>
						<img src="" alt="" />
					</span>{" "}
					<span>{userEmail}</span>
				</div>
			</div>

			<div className="flex flex-col lg:flex-row">
				{/* Form */}
				<form
					onSubmit={formik.handleSubmit}
					className="bg-grayish rounded-lg shadow-xl p-5 md:p-10 max-w-[700px] mx-6 my-5 flex flex-col gap-5 flex-1"
				>
					<div className="flex flex-col md:flex-row justify-between gap-3">
						<div className="flex flex-col gap-1">
							<label htmlFor="firstName">
								First Name{" "}
								<span className="text-red-500">*</span>
							</label>
							<input
								type="text"
								placeholder="ifedimeji"
								name="firstName"
								id="firstName"
								onChange={formik.handleChange}
								value={formik.values.firstName}
								onBlur={formik.handleBlur}
								className="h-9 rounded-md p-2 border border-gray-500 bg-grayish text-md placeholder-gray-500"
							/>

							{formik.touched.firstName &&
							formik.errors.firstName ? (
								<div className="text-red-500">
									{formik.errors.firstName}
								</div>
							) : null}
						</div>
						<div className="flex flex-col gap-1">
							<label htmlFor="lastName">
								Last Name{" "}
								<span className="text-red-500">*</span>
							</label>
							<input
								type="text"
								placeholder="Omoniyi"
								name="lastName"
								id="lastName"
								onChange={formik.handleChange}
								value={formik.values.lastName}
								onBlur={formik.handleBlur}
								className="h-9 rounded-md p-2 border border-gray-500 bg-grayish text-md placeholder-gray-500"
							/>

							{formik.touched.lastName &&
							formik.errors.lastName ? (
								<div className="text-red-500">
									{formik.errors.lastName}
								</div>
							) : null}
						</div>
					</div>

					<div className="flex flex-col gap-1">
						<label htmlFor="education">
							Educational Institution{" "}
							<span className="text-red-500">*</span>
						</label>
						<input
							type="text"
							placeholder="University of Lagos State"
							name="education"
							id="education"
							onChange={formik.handleChange}
							value={formik.values.education}
							onBlur={formik.handleBlur}
							className="h-9 rounded-md p-2 border border-gray-500 bg-grayish text-md placeholder-gray-500"
						/>

						{formik.touched.education && formik.errors.education ? (
							<div className="text-red-500">
								{formik.errors.education}
							</div>
						) : null}
					</div>

					<div className="flex flex-col gap-1">
						<label htmlFor="role">
							Role <span className="text-red-500">*</span>
						</label>
						<input
							type="text"
							name="role"
							placeholder="Frontend Developer"
							onChange={formik.handleChange}
							value={formik.values.role}
							onBlur={formik.handleBlur}
							className="h-9 rounded-md p-2 border border-gray-500 bg-grayish text-md placeholder-gray-500"
						/>

						{formik.touched.role && formik.errors.role ? (
							<div className="text-red-500">
								{formik.errors.role}
							</div>
						) : null}
					</div>

					<div className="flex flex-col gap-1">
						<label htmlFor="skills">
							Skills <span className="text-red-500">*</span>
						</label>
						<input
							type="text"
							name="skills"
							placeholder="Up to 5 skills, seperated by a comma"
							onChange={formik.handleChange}
							value={formik.values.skills}
							onBlur={formik.handleBlur}
							className="h-9 rounded-md p-2 border border-gray-500 bg-grayish text-md placeholder-gray-500"
						/>

						{formik.touched.skills && formik.errors.skills ? (
							<div className="text-red-500">
								{formik.errors.skills}
							</div>
						) : null}
					</div>

					<div className="flex flex-col lg:flex-row justify-between gap-4">
						<div className="flex flex-col gap-1">
							<label htmlFor="github">Github Profile</label>
							<input
								type="text"
								name="github"
								placeholder="https://github.com/yourname"
								onChange={formik.handleChange}
								value={formik.values.github}
								onBlur={formik.handleBlur}
								className="h-9 rounded-md p-2 border border-gray-500 bg-grayish text-md placeholder-gray-500"
							/>

							{formik.touched.github && formik.errors.github ? (
								<div className="text-red-500">
									{formik.errors.github}
								</div>
							) : null}
						</div>
						<div className="flex flex-col gap-1">
							<label htmlFor="twitter">Twitter Profile</label>
							<input
								type="text"
								name="twitter"
								onChange={formik.handleChange}
								value={formik.values.twitter}
								onBlur={formik.handleBlur}
								className="h-9 rounded-md p-2 border border-gray-500 bg-grayish text-md placeholder-gray-500"
							/>
						</div>
					</div>

					<div className="flex flex-col gap-1">
						<label htmlFor="about">About You</label>
						<textarea
							name="about"
							placeholder="Tell us a little about yourself..."
							id="about"
							onChange={formik.handleChange}
							value={formik.values.about}
							onBlur={formik.handleBlur}
							className="h-20 rounded-md p-2 border border-gray-500 bg-grayish text-md placeholder-gray-500 resize-none"
						></textarea>

						{formik.touched.about && formik.errors.about ? (
							<div className="text-red-500">
								{formik.errors.about}
							</div>
						) : null}
					</div>
				</form>

				{/* Submit button */}
				<div className="flex-1 flex flex-col items-center justify-center md:w-full m-5">
					<div className="p-5 flex flex-col items-center max-w-[500px] w-full bg-grayish shadow-xl rounded-lg lg:p-10">
						<button
							onClick={formik.handleSubmit}
							type="submit"
							className="w-full primary-gradient p-2 rounded-lg  text-xl font-semibold hover:opacity-90 active:scale-90"
							disabled={loading}
						>
							{loading
								? "Submitting Application..."
								: "Submit application"}
						</button>
					</div>
					<NavLink
						to="/register-team"
						className="text-primary active:scale-90 p-4   w-full flex justify-end hover:opacity-85 pt-5"
					>
						Register a team ?
					</NavLink>
				</div>
			</div>

			{/* footer */}
			<Footer />
			<ToastContainer theme="dark" />
		</motion.div>
	);
};

export default HackathonReg;

import React from "react";
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";
import AdminEventsCard from "../components/AdminEventsCard";
import Image from "../assets/Placeholder_assets/BitcoinImg.png";
import { motion } from "framer-motion";

const AdminEvents = () => {
	return (
		<div className="flex flex-row text-white">
			<Sidebar />

			<motion.div
				className="w-full p-5 md:p-8"
				initial={{ opacity: 0, y: 50 }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, y: -50 }}
				transition={{ duration: 0.5 }}
			>
				<TopBar />

				<div className="flex flex-row flex-wrap gap-5">
					<div>
						<h2 className="font-bold p-3 text-3xl">
							Upcoming Event
						</h2>

						<div>
							<AdminEventsCard
								title="/dApps over apps conference Unilag 2024"
								image={Image}
							/>
						</div>
					</div>

					<div className="bg-grayish rounded-xl border border-gray-700 ">
						<h2 className="text-xl font-semibold p-4 border border-gray-600">
							Event Details
						</h2>
						<form action="" className="flex flex-col gap-4 p-4 ">
							<div className="flex flex-col gap-1">
								<label htmlFor="Description">Description</label>
								<textarea
									name=""
									id=""
									cols="30"
									rows="3"
									placeholder="Event Description"
									className="bg-black border rounded-lg p-2 border-gray-500"
								></textarea>
							</div>
							<div className="flex flex-col gap-1">
								<label htmlFor="date">Event Date</label>
								<input
									className="bg-black border rounded-lg h-8 p-2 border-gray-500"
									type="date"
									id="date"
								/>
							</div>
							<div className="flex flex-col gap-1">
								<label htmlFor="time">Start Time</label>
								<input
									className="bg-black border rounded-lg h-8 p-2 border-gray-500"
									type="time"
									id="time"
								/>
							</div>
							<div className="flex flex-col gap-1">
								<label htmlFor="location">Location</label>
								<input
									className="bg-black border rounded-lg h-8 p-2 border-gray-500"
									type="text"
									id="location"
								/>
							</div>
							<div className="flex flex-col gap-1">
								<label htmlFor="cover">Cover Image</label>
								<input type="file" name="cover" id="cover" />
							</div>
							<div className="flex flex-col gap-1">
								<button
									type="submit"
									className="primary-gradient p-2 px-3 rounded-lg w-fit flex items-center justify-center"
								>
									Save Event
								</button>
							</div>
						</form>
					</div>
				</div>
			</motion.div>
		</div>
	);
};

export default AdminEvents;

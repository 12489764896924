import Logo from "../assets/Home_Assets/image_1-removebg-preview.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import Footer from "../components/Footer/Footer";
import { BASE_URL } from "../utils/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { motion } from "framer-motion";

const TeamReg = () => {
	const handleSubmit = async (values) => {
		const response = await fetch(`${BASE_URL}/team`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(values),
		});
		if (!response.ok) {
			toast.error("Something went wrong");
		} else toast("Application successful!");
	};

	//schema and configuration for form validation
	const initialValues = {
		teamName: "",
		teammate1: "",
		teammate2: "",
		teammate3: "",
		email: localStorage.getItem("email"),
	};
	const validationSchema = Yup.object({
		teamName: Yup.string().required("Please enter team name"),
		teammate1: Yup.string()
			.required("Please enter the email of at least one teammate")
			.email("Invalid email address"),
		teammate2: Yup.string().email("Invalid email address"),
		teammate3: Yup.string().email("Invalid email address"),
	});
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			console.log(values)
			handleSubmit(values);
		},
	});

	return (
		<motion.div
			initial={{ opacity: 0, x: 100 }}
			animate={{ opacity: 1, x: 0 }}
			exit={{ opacity: 0, x: -100 }}
			className="text-white"
		>
			{/* Display bar */}
			<div className="flex flex-row gap-3 bg-grayish px-2 items-center justify-between p-2">
				<div>
					<img src={Logo} alt="" className="h-12 w-12 rotate" />
				</div>
				<div className="hidden lg:block">
					<h1 className="font-semibold text-xl">
						Open Web Tour Hackathon
					</h1>

					<div className="text-gray-400">Get registered here</div>
				</div>
				<div>
					<span>
						<img src="" alt="" />
					</span>{" "}
					<span>{localStorage.getItem("email")}</span>
				</div>
			</div>

			<div className="flex lg:flex-row flex-col lg:gap-10 gap-5 lg:pt-10 pt-5 m-10 items-center">
				<div className="bg-grayish px-2 flex-1">
					{/* info */}
					<div className="rounded-lg shadow-xl primary-gradient w-fit p-4 flex flex-col gap-4 m-5 mb-10">
						<div>
							{" "}
							This Hackathon allows you have a maximum of 4
							members per team.
						</div>
						<div>
							Input your Teammates email addresses to add them to
							your mail
						</div>
					</div>

					{/* Form */}
					<form onSubmit={formik.handleSubmit}>
						<div className="flex flex-col gap-4 p-4">
							<div className="flex flex-col gap-2">
								<label htmlFor="">
									Team Name{" "}
									<span className="text-red-500">*</span>
								</label>
								<input
									type="text"
									name="teamName"
									onChange={formik.handleChange}
									value={formik.values.teamName}
									onBlur={formik.handleBlur}
									placeholder="Hackathon Slayers"
									className="h-9 bg-grayish px-2 rounded border border-gray-600"
								/>

								{formik.touched.teamName &&
								formik.errors.teamName ? (
									<div className="text-red-500">
										{formik.errors.teamName}
									</div>
								) : null}
							</div>
							<div className="flex flex-col gap-2">
								<label htmlFor="">
									Teammate 1 Email{" "}
									<span className="text-red-500">*</span>
								</label>
								<input
									type="email"
									name="teammate1"
									onChange={formik.handleChange}
									value={formik.values.teammate1}
									onBlur={formik.handleBlur}
									placeholder="teammate@gmail.com"
									className="h-9 bg-grayish px-2 rounded border border-gray-600"
								/>

								{formik.touched.teammate1 &&
								formik.errors.teammate1 ? (
									<div className="text-red-500">
										{formik.errors.teammate1}
									</div>
								) : null}
							</div>
							<div className="flex flex-col gap-2">
								<label htmlFor="">Teammate 2 Email</label>
								<input
									type="email"
									name="teammate2"
									onChange={formik.handleChange}
									value={formik.values.teammate2}
									onBlur={formik.handleBlur}
									placeholder="teammate@gmail.com"
									className="h-9 bg-grayish px-2 rounded border border-gray-600"
								/>

								{formik.touched.teammate2 &&
								formik.errors.teammate2 ? (
									<div className="text-red-500">
										{formik.errors.teammate2}
									</div>
								) : null}
							</div>
							<div className="flex flex-col gap-2">
								<label htmlFor="">Teammate 3 Email</label>
								<input
									type="email"
									name="teammate3"
									onChange={formik.handleChange}
									value={formik.values.teammate3}
									onBlur={formik.handleBlur}
									placeholder="teammate@gmail.com"
									className="h-9 bg-grayish px-2 rounded border border-gray-600"
								/>

								{formik.touched.teammate3 &&
								formik.errors.teammate3 ? (
									<div className="text-red-500">
										{formik.errors.teammate3}
									</div>
								) : null}
							</div>
						</div>
					</form>
				</div>

				{/* submit */}
				<div className="flex-1 bg-grayish rounded-lg shadow-lg flex items-center justify-center h-fit p-5">
					<div className="max-w-[300px]">
						<button
							className="w-full primary-gradient p-2 rounded-lg  text-xl font-semibold hover:opacity-90 active:scale-90"
							type="submit"
							onClick={formik.handleSubmit}
						>
							Submit Team application
						</button>
					</div>
				</div>
			</div>

			{/* Footer */}
			<Footer />
			<ToastContainer theme="dark" />
		</motion.div>
	);
};

export default TeamReg;

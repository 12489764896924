import { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";
import { BASE_URL } from "../utils";

const AdminHackathon = () => {
	const [individuals, setIndividuals] = useState([]);
	const [teams, setTeams] = useState([]);
	const [error, setError] = useState(null);
	console.log(individuals);
	console.log(error);

	useEffect(() => {
		fetch(`${BASE_URL}/team`)
			.then((response) => {
				if (!response.ok) {
					throw new Error(
						"Network response was not ok " + response.statusText
					);
				}
				return response.json();
			})
			.then((data) => setTeams(data))
			.catch((error) => setError(error));

		fetch(`${BASE_URL}/individual`)
			.then((response) => {
				if (!response.ok) {
					throw new Error(
						"Network response was not ok " + response.statusText
					);
				}
				return response.json();
			})
			.then((data) => setIndividuals(data))
			.catch((error) => setError(error));
	}, []);

	if (error) {
		return (
			<div className="text-white text-2xl flex w-full h-screen items-center justify-center">
				Error: {error.message}
			</div>
		);
	}

	return (
		<div className="flex flex-row w-full text-white">
			<Sidebar />

			<div className="w-full text-white">
				<TopBar />
				<div>
					<h2 className="text-2xl px-5 pb-5 font-bold">
						Individual Registrations
					</h2>
				</div>

				{/* <div className="flex flex-row justify-between gap-3 px-5 p-2 border border-gray-700 w-[90%] m-auto text-xl font-semibold">
						<div>First Name</div>
						<div>Last Name</div>
						<div>Role</div>
						<div>Email</div>
						<div>Skills</div>
						<div>Github</div>
						<div>Twitter</div>
					</div>
				{individuals?.map((individual) => (
					<div className="flex flex-row gap-3 px-5 p-2 border border-gray-900 w-[90%] m-auto items-start">
						<div className="flex-1">{individual.firstName}</div>
						<div className="flex-1">{individual.lastName}</div>
						<div className="flex-1">{individual.role}</div>
						<div className="flex-1">{individual.email}</div>
						<div className="flex-1">{individual.skills}</div>
						<div className="flex-1">{individual.github}</div>
						<div className="flex-1">{individual.twitter}</div>
					</div>
				))} */}

				<div className=" overflow-x-scroll p-10">
					<table className="min-w-full divide-y bg-black divide-gray-200 overflow-scroll p-10 table-fixed">
						<thead className=" bg-black">
							<tr>
								<th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									First Name
								</th>
								<th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Last Name
								</th>
								<th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Role
								</th>
								<th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Email
								</th>
								<th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Skills
								</th>
								<th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Education
								</th>
								<th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Github
								</th>
								<th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Twitter
								</th>
							</tr>
						</thead>
						<tbody className="bg-black divide-y divide-gray-600 text-sm">
							{individuals.map((details) => (
								<tr>
									<td className="px-3 py-4 whitespace-nowrap">
										{details.firstName}
									</td>
									<td className="px-3 py-4 whitespace-nowrap">
										{details.lastName}
									</td>
									<td className="px-3 py-4 whitespace-nowrap">
										{details.role}
									</td>
									<td className="px-3 py-4 whitespace-nowrap">
										{details.email}
									</td>
									<td className="px-3 py-4 whitespace-nowrap">
										{details.skills}
									</td>

									<td className="px-3 py-4 whitespace-nowrap">
										{details.education}
									</td>
									<td className="px-3 py-4 whitespace-nowrap">
										{details.github}
									</td>
									<td className="px-3 py-4 whitespace-nowrap">
										{details.twitter}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>

				{/* Team Reg table details */}

				<div className="p-10 w-full overflow-scroll">
					<div className="text-2xl px-5 pb-5 font-bold">
						Team Registrations
					</div>
					<table className="min-w-full divide-y bg-black divide-gray-200 overflow-scroll p-10 table-fixed">
						<thead className="bg-black">
							<th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
								Team Name
							</th>
							<th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
								Registrar's email
							</th>
							<th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
								Teammate 1 email
							</th>{" "}
							<th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
								Teammate 2 email
							</th>{" "}
							<th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
								Teammate 3 email
							</th>
						</thead>

						<tbody className="bg-black divide-y divide-gray-600 text-sm">
							{teams.map((details) => (
								<tr>
									<td className="px-3 py-4 whitespace-nowrap">
										{details.teamName}
									</td>
									<td className="px-3 py-4 whitespace-nowrap">
										{details.email}
									</td>

									<td className="px-3 py-4 whitespace-nowrap">
										{details.teammate1}
									</td>
									<td className="px-3 py-4 whitespace-nowrap">
										{details.teammate2}
									</td>
									<td className="px-3 py-4 whitespace-nowrap">
										{details.teammate3}{" "}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default AdminHackathon;

import React from "react";
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";
import { BsPlusSquare } from "react-icons/bs";
import { motion } from "framer-motion";

const AdminBlogsAdd = () => {
	return (
		<div className="flex flex-row gap-2 w-full text-white">
			<Sidebar />

			<motion.div
				className="p-4 w-full flex flex-col"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{ duration: 0.5 }}
			>
				<TopBar />

				<div className="p-4 flex flex-row">
					<div className="border-r border-r-gray-500 flex-1 p-4">
						<h2 className="text-xl font-bold py-5">Add Title</h2>
						<label
							htmlFor="cover"
							className="h-[100px] w-full border border-dashed flex flex-col justify-center items-center"
						>
							{" "}
							<BsPlusSquare /> Add Cover Image
						</label>
						<input
							type="file"
							name="cover"
							id="cover"
							className=""
						/>
						<textarea
							name="content"
							id="content"
							cols="30"
							rows="10"
							className="w-full h-[150px] p-2 border border-black rounded-xl my-3 bg-grayish "
							placeholder="Type Blog Post..."
						></textarea>
					</div>

					<div className="flex-1">
						<div className="border-b border-b-gray-500 flex items-center justify-center p-10">
							<button className="primary-gradient p-2 px-3 rounded-md">
								Publish Post
							</button>
						</div>
						<div>
							<div className="flex flex-col p-4 gap-2">
								<label htmlFor="Categories">Categories</label>
								<input
									className="h-8 rounded-md p-2 bg-grayish"
									type="text"
									placeholder="NFT, Metamask"
								/>
							</div>
							<div className="flex flex-col p-4 gap-2">
								<label htmlFor="Author">Author</label>
								<input
									className="h-8 rounded-md p-2 bg-grayish"
									type="text"
									placeholder="dApps over apps team"
								/>
							</div>
						</div>
					</div>
				</div>
			</motion.div>
		</div>
	);
};

export default AdminBlogsAdd;

import React from "react";
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";
import { BiPlay } from "react-icons/bi";
import { motion } from "framer-motion";
import AdminBlogCard from "../components/AdminBlogCard";
import Image from "../assets/Placeholder_assets/BitcoinImg.png";

const AdminEdu = () => {
	return (
		<div className="flex w-full text-white">
			<Sidebar />

			<motion.div
				className="w-full p-5"
				initial={{ opacity: 0, x: 100 }}
				animate={{ opacity: 1, x: 0 }}
				exit={{ opacity: 0, x: -100 }}
			>
				<TopBar />
				<div className="flex flex-col border-dashed p-10 border border-gray-600 w-fit m-3">
					<BiPlay className="scale-150" />
				</div>
				Upload New Video
				<h2 className="text-secondary text-xl font-bold py-5">
					Education Contents
				</h2>
				<div>
					<AdminBlogCard
						title="Introduction to Blockchain Technology"
						image={Image}
						tags={["Beginner","tags"]}
					/>
				</div>
			</motion.div>
		</div>
	);
};

export default AdminEdu;

import React from "react";
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";
import BlogCard from "../components/BlogCard";
import BitcoinImg from "../assets/Placeholder_assets/BitcoinImg.png";
import TutorialCard from "../components/TutorialCard";
import Image from "../assets/Events_Assets/Component 28.png";
import { motion } from "framer-motion";

const Dashboard = () => {
	return (
		<div className="text-white bg-black w-full flex flex-row">
			<Sidebar />

			<motion.div
				className="w-full p-5"
				initial={{ opacity: 0, y: 50 }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, y: -50 }}
				transition={{ duration: 0.5 }}
			>
				<TopBar />

				{/* cards */}
				{/* individual card styling in index.css file */}
				<div className="flex flex-row gap-4 flex-wrap justify-between  p-3 mb-10 ">
					<div className="dashboard-card-bg1 p-3 flex justify-end flex-col gap-1">
						<div>Team Members</div>
						<div>12</div>
					</div>
					<div className="dashboard-card-bg2 p-3 flex justify-end flex-col gap-1">
						<div>Blog Posts</div>
						<div>12</div>
					</div>
					<div className="dashboard-card-bg3 p-3 flex justify-end flex-col gap-1">
						<div>Tutorial Videos</div>
						<div>12</div>
					</div>
					<div className="dashboard-card-bg4 p-3 flex justify-end flex-col gap-1">
						<div>Events</div>
						<div>1</div>
					</div>
				</div>
				<div>
					<div className="flex flex-row flex-wrap gap-8">
						<div className="flex flex-col ">
							<h2 className="text-xl font-bold p-3 text-primary">
								Recent Blog Posts
							</h2>
							<BlogCard
								img={BitcoinImg}
								title="How NFTs can drive Crypto adoption through Blockchain education"
								likes={1540}
								date={"24 June 2023"}
								text="The common goal uniting multiple chains and protocol in crypto is adoption. Whether you're buildig the next AMM or centralized exchange of NFT marketplace or event the metaverse, the goal is to see an ever increasing"
							/>
						</div>

						<div>
							<h2 className="text-xl font-bold p-3 text-secondary">
								Recent Tutorial Video
							</h2>

							<TutorialCard
								title="Introduction to Blockchain Technology"
								level="Beginner"
								image={Image}
							/>
						</div>
					</div>
				</div>
			</motion.div>
		</div>
	);
};

export default Dashboard;

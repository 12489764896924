import React from "react";

const AdminEventsCard = ({ image, title, edit }) => {
	return (
		<div className="w-[320px]">
			<div>
				<img src={image} className="w-full" alt="" />
			</div>
			<div className="text-xl p-2">{title}</div>
			<button className="hover:bg-primary  p-1 px-2 border border-primary rounded-md">
				Edit Name
			</button>
		</div>
	);
};

export default AdminEventsCard;

import AdminBlogCard from "../components/AdminBlogCard";
import Sidebar from "../components/Sidebar";
import { motion } from "framer-motion";
import TopBar from "../components/TopBar";
import { Link } from "react-router-dom";
import { FaPlusSquare } from "react-icons/fa";

const ManageHackathon = () => {
	return (
		<div className="flex flex-row text-white">
			<Sidebar />

			<motion.div
				className="w-full p-5 md:p-8"
				initial={{ opacity: 0, y: 50 }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, y: -50 }}
				transition={{ duration: 0.5 }}
			>
				<TopBar />

				<Link
					to="/admin/blog/add"
					className="flex items-center justify-start flex-col w-fit"
				>
					<div className="m-2 p-10 border-dashed border w-fit">
						<FaPlusSquare className="scale-150" />
					</div>
					Add new Hackathon
				</Link>

				<h2 className="text-secondary font-bold text-2xl py-4">
					Open Hackathons
				</h2>

				<div className="flex flex-wrap gap-3 items-center justify-center">
					<AdminBlogCard
						title="How NFTs can drive Crypto adoption through Blockchain Education"
						tags={["NFT", "Metamask"]}
						image={Image}
					/>
					<AdminBlogCard
						title="How NFTs can drive Crypto adoption through Blockchain Education"
						tags={["NFT", "Metamask"]}
						image={Image}
					/>
					<AdminBlogCard
						title="How NFTs can drive Crypto adoption through Blockchain Education"
						tags={["NFT", "Metamask"]}
						image={Image}
					/>
				</div>
			</motion.div>
		</div>
	);
};

export default ManageHackathon;

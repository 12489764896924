import React, { useState } from "react";
import useProgressiveImg from "../hooks/useProgressiveImg";

import Footer from "../components/Footer/Footer";
import Page from "../components/Page";
import HackathonImg from "../assets/HackathonImg.jpeg";
import HackathonImgBlur from "../assets/HackathonImgBlur.jpg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../utils";

const HackathonPage = () => {
	const [src, { blur }] = useProgressiveImg(HackathonImgBlur, HackathonImg);

	// Loading state
	const [loading, setLoading] = useState(false);

	// Submit logic
	const handleSubmit = async (values) => {
		setLoading(true); // Start loading
		try {
			const response = await fetch(`${BASE_URL}/tickets`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(values),
			});
			const data = await response.json();
			console.log(data);
			toast.success("Registration successful");
		} catch (error) {
			console.error("Error submitting form:", error);
			toast.error("Failed to submit form");
		} finally {
			setLoading(false); // Stop loading
		}
	};

	// Schema and config for form validation
	const initialValues = {
		lastName: "",
		firstName: "",
		email: "",
	};

	const validationSchema = Yup.object({
		lastName: Yup.string().required("Please enter your last name"),
		firstName: Yup.string().required("Please enter your first name"),
		email: Yup.string()
			.email("Invalid email address")
			.required("Please enter your email address"),
	});

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values, { resetForm }) => {
			console.log(values);
			handleSubmit(values);
			resetForm();
		},
	});

	return (
		<Page className="text-white">
			<div className="flex flex-row w-full items-center justify-center pt-5">
				<img className={blur? "blur-xl transition-all flex max-h-[500px]" : "transition-all flex max-h-[500px]"} src={src} alt="" />
			</div>

			<div className="text-center p-5 font-extrabold text-4xl gap-4">
				<span className="text-primary">Dapps over apps</span> conference
				Unilag 2024
			</div>

			<div className="flex flex-col max-w-5xl m-auto pt-10 mt-20">
				<h2 className="text-primary text-3xl font-extrabold pb-4">
					Register Now
				</h2>
				<form
					onSubmit={formik.handleSubmit}
					className="rounded-lg shadow-xl p-2 md:p-10 md:mx-10 my-5 flex flex-col gap-5 flex-1 text-lg"
				>
					<div className="flex flex-col lg:flex-row justify-between md:gap-10 gap-5">
						<div className="flex flex-col gap-1 flex-1">
							<label htmlFor="firstName">
								First Name{" "}
								<span className="text-red-500">*</span>
							</label>
							<input
								type="text"
								placeholder="ifedimeji"
								name="firstName"
								id="firstName"
								onChange={formik.handleChange}
								value={formik.values.firstName}
								onBlur={formik.handleBlur}
								className="h-9 rounded-md p-2 border border-gray-500 bg-grayish text-md placeholder-gray-500 min-w-[230px]"
							/>
							{formik.touched.firstName &&
							formik.errors.firstName ? (
								<div className="text-red-500">
									{formik.errors.firstName}
								</div>
							) : null}
						</div>
						<div className="flex flex-col gap-1 flex-1">
							<label htmlFor="lastName">
								Last Name{" "}
								<span className="text-red-500">*</span>
							</label>
							<input
								type="text"
								placeholder="Omoniyi"
								name="lastName"
								id="lastName"
								onChange={formik.handleChange}
								value={formik.values.lastName}
								onBlur={formik.handleBlur}
								className="h-9 rounded-md p-2 border border-gray-500 bg-grayish text-md placeholder-gray-500 min-w-[230px]"
							/>
							{formik.touched.lastName &&
							formik.errors.lastName ? (
								<div className="text-red-500">
									{formik.errors.lastName}
								</div>
							) : null}
						</div>
					</div>

					<div className="flex flex-col gap-1">
						<label htmlFor="email">
							Email <span className="text-red-500">*</span>
						</label>
						<input
							type="email"
							placeholder="youremail@gmail.com"
							name="email"
							id="email"
							onChange={formik.handleChange}
							value={formik.values.email}
							onBlur={formik.handleBlur}
							className="h-9 rounded-md p-2 border border-gray-500 bg-grayish text-md placeholder-gray-500 min-w-[230px]"
						/>
						{formik.touched.email && formik.errors.email ? (
							<div className="text-red-500">
								{formik.errors.email}
							</div>
						) : null}
					</div>
				</form>

				<button
					className="w-fit p-2 primary-gradient rounded px-5 hover:animate-pulse flex m-auto"
					onClick={formik.handleSubmit}
					type="submit"
					disabled={loading} // Disable the button when loading
				>
					{loading ? "Submitting..." : "Get Ticket"}
				</button>
			</div>

			<Footer />
			<ToastContainer theme="dark" />
		</Page>
	);
};

export default HackathonPage;

import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const Login = () => {
	const navigate = useNavigate();
	const handleLogin = () => {
		setTimeout(() => {
			navigate("/dashboard");
		}, 2000);
	};

	// Schema for form handling
	const initialValues = {
		username: "",
		password: "",
	};
	const validationSchema = Yup.object({
		username: Yup.string().required("Enter your username"),
		password: Yup.string().required("Enter your password"),
	});

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values, { resetForm }) => {
			console.log(values);
			if (values.username !== "supercoolkay" || values.password !== "superKoolKay") {
				alert("Invalid credentials");
				return;
			}
			resetForm();
			handleLogin();
		},
	});

	return (
		<motion.div className="login-bg text-white h-screen w-full flex items-center justify-center text-xl p-5">
			<form
				action=""
				className="shadow-xl p-5 md:p-10 flex flex-col items-center opacity-85 rounded-2xl h-[500px] border border-gray-700 bg-black justify-between max-w-[500px]"
			>
				<h1 className="text-3xl font-bold">Log in</h1>
				<h4>
					Log in to your admin dashboard with your personal password
					and username
				</h4>
				<div className="flex flex-col gap-2 w-full">
					<label htmlFor="">Username</label>
					<input
						type="text"
						name="username"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.username}
						className="h-9 bg-grayish rounded border border-gray-600"
					/>
				</div>
				<div className="flex flex-col gap-2 w-full">
					<label htmlFor="">Password</label>
					<input
						type="password"
						name="password"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.password}
						className="h-9 bg-grayish rounded border border-gray-600"
					/>
				</div>

				<button
					type="submit"
					onClick={formik.handleSubmit}
					className="w-full primary-gradient p-2 rounded-lg mt-10"
				>
					Log In
				</button>
			</form>
		</motion.div>
	);
};

export default Login;

import GoogleIcon from "../assets/Login_Assets/GoogleIcon.png";
import logo from "../assets/Home_Assets/image_1-removebg-preview.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { motion } from "framer-motion";


const MemberLogin = () => {
	const navigate = useNavigate();
	const handleLogin = () => {
		setTimeout(() => {
			navigate("/register");
		}, 2000);
	};

	// validation and schema for form handling
	const initialValues = {
		email: "",
	};
	const validationSchema = Yup.object({
		email: Yup.string()
			.email("Invalid email address")
			.required("Please enter your email address"),
	});

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values, { resetForm }) => {
			console.log(values);
			toast("Login successful!");
			localStorage.clear();
			localStorage.setItem("email", values.email);
			resetForm();
			handleLogin();
		},
	});

	return (
		<motion.div className="bg-gray-950 h-screen">
			<div className="text-white p-10 rounded bg-black h-full w-fit flex flex-col justify-evenly">
				<div className="py-5">
					<img src={logo} alt="" className={" rotate"} />
				</div>
				<h1 className="text-3xl font-bold">Log In</h1>
				<div className="text-lg text-gray-500">
					Register for the Open Web Tour Hackathon here
				</div>

				<button className="flex flex-row gap-2 items-center p-2 bg-gray-800 justify-center w-full rounded-lg hover:opacity-60 cursor-not-allowed opacity-60">
					<img src={GoogleIcon} alt="" /> Sign up with Google
				</button>

				<div className="text-center">Or</div>

				<form action="" className="flex flex-col gap-2">
					<label htmlFor="Email">Email</label>
					<input
						type="text"
						name="email"
						onChange={formik.handleChange}
						value={formik.values.email}
						onBlur={formik.handleBlur}
						placeholder="dappsoverapps@gmail.com"
						className="h-9 rounded-md p-2 border border-gray-500 bg-grayish text-lg"
					/>

					{formik.touched.email && formik.errors.email ? (
						<div className="text-red-500 text-sm">
							{formik.errors.email}
						</div>
					) : null}
				</form>

				<button
					onClick={formik.handleSubmit}
					type="submit"
					className="w-full primary-gradient p-2 rounded-lg mt-10 text-xl font-semibold"
				>
					Log In
				</button>
			</div>
			<ToastContainer theme="dark"/>
		</motion.div>
	);
};

export default MemberLogin;

import React from "react";
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";

const AdminPortfolio = () => {
	return (
		<div className="w-full flex text-white">
			<Sidebar />

			<div className="w-full p-5">
				<TopBar />

				<div className="flex flex-row gap-5 flex-wrap pt-8">
					<div className="flex flex-col rounded-lg bg-grayish w-fit pb-5">
						<h2 className="font-semibold text-xl border border-gray-600 p-3">
							Team Member
						</h2>
						{data.map((event, index) => (
							<div key={index}>
								<div className="p-4 pr-10 border-b border-x border-gray-500">
									{event.title}
								</div>
							</div>
						))}

						<div className="pt-10 flex items-center justify-center">
							<button className="p-2 px-3 primary-gradient">
								Add New Member
							</button>
						</div>
					</div>
          

          {/* Project details form */}
					<div className="border border-gray-500 shadow-xl bg-grayish">
            <h3 className="p-5 text-xl font-semibold ">Project Details</h3>
            <form action="" className="p-5 flex flex-col gap-5">
              <div className="flex flex-col gap-2">
                <label htmlFor="Project-name">Project Name</label>
                <input type="text" className="bg-black rounded-lg h-8 p-2 border border-gray-500"/>
              </div>

              <div className="flex flex-col gap-2">
                <label htmlFor="About">About Project</label>
                <textarea name="" id="" cols="30" rows="3" className="bg-black rounded-lg p-1 border border-gray-500"></textarea>
              </div>

              <div className="flex flex-col gap-2">
                <label htmlFor="images">Image(s)</label>
                <input type="file" />
              </div>

              <button className="rounded-lg p-1 px-2 primary-gradient hover:opacity-85">Save Project</button>
            </form>
          </div>
				</div>
			</div>
		</div>
	);
};

export default AdminPortfolio;

const data = [
	{
		title: "dapps over apps conference",
	},
	{
		title: "dapps over apps conference",
	},
	{
		title: "dapps over apps conference",
	},
	{
		title: "dapps over apps conference",
	},
];

import React from "react";
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";

const AdminTeam = () => {
	return (
		<div className="w-full flex flex-row text-white">
			<Sidebar />

			<div className="w-full p-3">
				<TopBar />

				<div className="p-5 flex flex-row flex-wrap gap-4">
					<div className="flex flex-col rounded-lg bg-grayish w-fit pb-5">
						<h2 className="font-semibold text-xl border border-gray-600 p-3">
							Team Member
						</h2>
						{teamData.map((member, index) => (
							<div key={index}>
								<div className="p-4 pr-10 border-b border-x border-gray-500">
									{member.name}
								</div>
							</div>
						))}

						<div className="pt-10 flex items-center justify-center">
							<button className="p-2 px-3 primary-gradient">
								Add New Member
							</button>
						</div>
					</div>

					{/* Member Details */}

					<form className="pt-0 rounded-lg bg-grayish">
						<h2 className="font-semibold text-xl border border-gray-600 p-3">
							Member Details
						</h2>
						<div className="flex flex-col p-4 gap-4 mt-0">
							<div className="flex flex-col">
								<label className="pb-1" htmlFor="name">Name</label>
								<input
									type="text"
									id="name"
									className="h-8 rounded-md p-2 border border-gray-500 bg-grayish"
								/>
							</div>
							<div className="flex flex-col">
								<label className="pb-1" htmlFor="position">Position</label>
								<input
									type="text"
									id="position"
									className="h-8 rounded-md p-2 border border-gray-500 bg-grayish"
								/>
							</div>
							<div className="flex flex-col">
								<label className="pb-1" htmlFor="twitter">Twitter</label>
								<input
									type="text"
									id="twitter"
									className="h-8 rounded-md p-2 border border-gray-500 bg-grayish"
								/>
							</div>
							<div className="flex flex-col">
								<label className="pb-1" htmlFor="linkedin">Linkedin Link</label>
								<input
									type="text"
									id="linkedin"
									className="h-8 rounded-md p-2 border border-gray-500 bg-grayish"
								/>
								<div className="flex flex-col py-3">
									<label className="pb-1" htmlFor="image">Image</label>
									<input
										type="file"
										id="image"
										className="h-8 rounded-md p-2 border border-gray-500 bg-grayish"
									/>
								</div>
								<div className="flex justify-end">
									<button className="p-2 px-3 primary-gradient">
										Save
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AdminTeam;

const teamData = [
	{
		name: "Omoniyi Ifedolapo Nifemi",
	},
	{
		name: "Muibi Muhammed",
	},
	{
		name: "SupercoolKay",
	},
	{
		name: "Emmanuel Ugwu",
	},
	{
		name: "Omoniyi Ifedolapo Nifemi",
	},
];

import React from "react";
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";
import { Link } from "react-router-dom";
import { FaPlusSquare } from "react-icons/fa";
import { motion } from "framer-motion";
import AdminBlogCard from "../components/AdminBlogCard";
import Image from "../assets/Placeholder_assets/BitcoinImg.png";

const AdminBlog = () => {
	return (
		<div className="flex flex-row w-full text-white">
			<Sidebar />

			<motion.div
				className="w-full md:px-8 px-4"
				initial={{ opacity: 0, x: 100 }}
				animate={{ opacity: 1, x: 0 }}
				exit={{ opacity: 0, x: -100 }}
			>
				<TopBar />

				<Link
					to="/admin/blog/add"
					className="flex items-center justify-start flex-col w-fit"
				>
					<div className="m-2 p-10 border-dashed border w-fit">
						<FaPlusSquare className="scale-150" />
					</div>
					Create New Blog Post
				</Link>

				<h2 className="text-secondary font-bold text-2xl py-4">
					Blog Posts
				</h2>

				<div className="flex flex-wrap gap-3 items-center justify-center">
					<AdminBlogCard
						title="How NFTs can drive Crypto adoption through Blockchain Education"
						tags={["NFT", "Metamask"]}
						image={Image}
					/>
					<AdminBlogCard
						title="How NFTs can drive Crypto adoption through Blockchain Education"
						tags={["NFT", "Metamask"]}
						image={Image}
					/>
					<AdminBlogCard
						title="How NFTs can drive Crypto adoption through Blockchain Education"
						tags={["NFT", "Metamask"]}
						image={Image}
					/>
				</div>
			</motion.div>
		</div>
	);
};

export default AdminBlog;

import React from "react";
import style from "../styles/About.module.css";
import Polygon_1 from "../assets/About_Assets/Polygon 2.png";
import Curve_1 from "../assets/About_Assets/Exclude.png";
import Curve_2 from "../assets/About_Assets/Exclude(1).png";
import Polygon_2 from "../assets/About_Assets/Polygon 6.png";
import About_Img from "../assets/About_Assets/Rectangle 4294.png";
import About_Img2 from "../assets/About_Assets/Rectangle 4295.png";
import Page from "../components/Page";

const About = () => {
	return (
		<Page className={style.container}>
			<div className={style.pink_blur}></div>
			<div className={style.vector__container}>
				<img src={Polygon_1} alt="" className={`${style.polygon_1} animate-pulse`} />
				<img src={Curve_1} alt="" className={style.curve_1} />
				<img src={Polygon_2} alt="" className={`${style.polygon_2} animate-pulse`} />
				<img src={Curve_2} alt="" className={style.curve_2} />
				<div className={style.container__text}>
					<h1>About dApps over apps</h1>
					<p>Learn more about dApps over apps and what we do</p>
				</div>
			</div>

			<div className={style.second__section}>
				<div className={style.second__section__col}>
					<div className={style.second__section__col__text}>
						<p>
							Dapps over Apps is an organization that aims to
							educate and onboard traditional developers, creative
							builders and tech enthusiasts to web3.
						</p>
						<p>
							Our Vision: To onboard 1,000,000 traditional
							developers, tech enthusiast and creative builders to
							web 3 by 2030.
						</p>
					</div>
					<img src={About_Img} alt="" />
				</div>
				<div className={style.second__section__col}>
					<img src={About_Img2} alt="" />
					<div className={style.second__section__col__text}>
						<p>
							Our mission: bridging the gap between traditional
							developers, tech enthusiasts, creative builders and
							blockchain technology.
						</p>
            <p>How do we intend to kickstart this mission:</p>
            <ul className="list list-disc flex flex-col gap-2 pt-2">
              <li>By organizing workshops and training.</li>
              <li>By organizing global conferences</li>
              <li>Through content creation and distribution</li>
              <li>Education and lectures</li>
              <li>By giving grants and support to likeminded individuals and projects</li>
              <li>By awarding prizes</li>
              <li>Collaboration with like-minded organizations and communities (both web2 & web3).</li>
            </ul>
					</div>
				</div>
			</div>

			<div className={style.third__section__flex}>
				<h1>
					Our <span>Values</span>
				</h1>
				<p>
					Blockchain has the potential to adequately transform African
					society. Offering insurmountable opportunities to those
					leveraging it to build a new structure in diverse sectors.
				</p>
				<div className={style.third__section__flex__purple_blur}></div>
				<div className={style.third__section__flex__pink_blur}></div>
				<div className={style.third__section__cols}>
					<div className={style.third__section__col}>
						<h2>Innovation</h2>
						<p>
							At dApps over apps, we embrace innovation as the
							cornerstone of our ethos. As a Web 3 brand, we are
							driven by a relentless pursuit of pioneering
							advancements that redefine what's possible in the
							digital realm
						</p>
					</div>
					<div className={style.third__section__col}>
						<h2>Accountability</h2>
						<p>
							Despite operating within a decentralized industry,
							we are committed to transparency as well as
							committed to bearing the cost of our decisions.
						</p>
					</div>
					<div className={style.third__section__col}>
						<h2>Sustainability</h2>
						<p>
							dApps over apps is focused on innovating for
							longevity, use, and impact. The short term isn’t in
							our plans to drive innovation.
						</p>
					</div>
				</div>
			</div>
		</Page>
	);
};

export default About;

import React from "react";
import { BiSearch } from "react-icons/bi";

const TopBar = () => {
	return (
		<div className="flex flex-row justify-between w-full p-4">
			<div className="flex flex-col gap-2">
				<h2>Hey SupercoolKay,</h2>
				<div className="text-gray-600 text-sm">
					What are we doing on our website today?
				</div>
			</div>

			<div className="relative md:block hidden">
				<BiSearch className="absolute left-2 top-2"/>
		
				<input type="text" className="w-[300px] bg-black border border-gray-600 p-2 pl-7 pr-20 rounded-md" />
		
				<button className="primary-gradient px-2 py-1 rounded-md absolute right-2 top-1 hover:opacity-75">
					Search
				</button>
			</div>

			<div className="flex flex-row gap-2">
				<img src="" alt="" />
				<div>SupercoolKay</div>
			</div>
		</div>
	);
};

export default TopBar;

import React, { useEffect } from "react";
import { motion } from "framer-motion";

const Page = ({ children, className = "", ...rest }) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<motion.div
			{...rest}
			className={`${className} fixctn`}
			initial={{ opacity: 0, y: 50 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: -50 }}
			transition={{ duration: 0.5 }}
		>
			{children}
		</motion.div>
	);
};

export default Page;

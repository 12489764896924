import { createBrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./views/Home";
import Blog from "./views/Blog";
import EducatonalContent from "./views/Educatonal_Content";
import About from "./views/About";
import Team from "./views/Team";
import Blog1 from "./views/Blog_1";
import Portfolio from "./views/Portfolio";
import EducationalContent1 from "./views/EducationalContent1";
import Portfolio1 from "./views/Portfolio1";
import Events from "./views/Events";
import NotFoundPage from "./views/NotFoundPage";
import Dashboard from "./views/Dashboard";
import AdminBlogsAdd from "./views/AdminBlogsAdd";
import AdminBlog from "./views/AdminBlog";
import AdminTeam from "./views/AdminTeam";
import AdminEvents from "./views/AdminEvents";
import AdminPortfolio from "./views/AdminPortfolio";
import AdminEdu from "./views/AdminEdu";
import Login from "./views/Login";
import MemberLogin from "./views/MemberLogin";
import HackathonReg from "./views/HackathonReg";
import TeamReg from "./views/TeamReg";
import HackathonPage from "./views/HackathonPage";
import AdminHackathon from "./views/AdminHackathon";
import ManageHackathon from "./views/ManageHackathon";
import Tickets from "./views/Tickets"

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      {
        path: "/educational_content",
        element: <EducatonalContent />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/team",
        element: <Team />,
      },
      {
        path: "/gallery/portfolio",
        element: <Portfolio />,
      },
      {
        path: "/blog/blog_1",
        element: <Blog1 />,
      },
      {
        path: "/educational_content/educational_content1",
        element: <EducationalContent1 />,
      },
      {
        path: "/portfolio/portfolio1",
        element: <Portfolio1 />,
      },
      {
        path: "gallery/events",
        element: <Events />,
      },
      {
        path: "/gallery/hackathon",
        element: <HackathonPage/>
      },
      {
        path: "/tickets",
        element: <Tickets/>
      }
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
  {
    path: "/dashboard",
    element: <Dashboard/>,
  },
  {
    path: "/admin/team",
    element: <AdminTeam/>,
  },
  {
    path: "/admin/events",
    element: <AdminEvents/>,
  },
  {
    path: "/admin/blog",
    element: <AdminBlog/>,
  },
  {
    path: "/admin/blog/add",
    element: <AdminBlogsAdd/>,
  },
  {
    path: "/admin/educational",
    element: <AdminEdu/>,
  },
  {
    path: "/admin/portfolio",
    element: <AdminPortfolio/>,
  },
  {
    path: "/admin/login",
    element: <Login/>
  },
  {
    path: "/login",
    element: <MemberLogin/>
  },
  {
    path: "/register",
    element: <HackathonReg/>
  },
  {
    path:"/register-team",
    element: <TeamReg/>
  },
  {
    path:"/admin/hackathon",
    element: <AdminHackathon/>
  },
  {
    path: "/admin/manage-hackathons",
    element: <ManageHackathon/>
  }
]);

//

import React, { useState } from "react";
import { BiNotepad } from "react-icons/bi";
import { BsCup, BsFileSpreadsheet } from "react-icons/bs";
import { MdPeople, MdSpaceDashboard } from "react-icons/md";
import { NavLink } from "react-router-dom";
import Logo from "../assets/Home_Assets/image_1-removebg-preview.png";
import { FaArrowLeft, FaLaptop, FaSpaceShuttle } from "react-icons/fa";

const Sidebar = () => {
	const [isNavOpen, setIsNavOpen] = useState(false);
	const handleCloseNav = () => {
		setIsNavOpen(!isNavOpen);
	};
	return (
		<div
			className={
				isNavOpen
					? "bg-grayish text-white flex-col md:flex-row text-base md:justify-between md:items-center h-full md:h-auto z-10 fixed left-0 w-[315px] md:static md:w-auto transition-all duration-500"
					: "bg-grayish text-white text-base flex flex-col md:flex-col md:min-w-[250px] h-full md:h-screen z-10 fixed left-[-100%] w-[315px] md:static md:w-auto transition-all duration-500"
			}
		>
			{/* Hamburger */}
			<div
				className={isNavOpen ? "hamburger active" : "hamburger"}
				onClick={handleCloseNav}
			>
				<span className="bar side"></span>
				<span className="bar side"></span>
				<span className="bar side"></span>
			</div>

			{/* Logo */}
			<div className="pb-10">
				<div>
					<img src={Logo} className="rotate" alt="" />
				</div>
			</div>

			<NavLink
				className={({ isActive }) => {
					return isActive
						? "flex flex-row gap-3 items-center rounded-xl p-2 text-white text-lg primary-gradient"
						: "flex flex-row gap-3 items-center rounded-xl p-2 text-white text-lg";
				}}
				to="/dashboard"
			>
				<MdSpaceDashboard />
				Dashboard
			</NavLink>
			<NavLink
				to="/admin/team"
				className={({ isActive }) => {
					return isActive
						? "flex flex-row gap-3 items-center rounded-xl p-2 text-white text-lg primary-gradient"
						: "flex flex-row gap-3 items-center rounded-xl p-2 text-white text-lg";
				}}
			>
				<MdPeople />
				Team Members
			</NavLink>
			<NavLink
				to="/admin/events"
				className={({ isActive }) => {
					return isActive
						? "flex flex-row gap-3 items-center rounded-xl p-2 text-white text-lg primary-gradient"
						: "flex flex-row gap-3 items-center rounded-xl p-2 text-white text-lg";
				}}
			>
				<BsCup />
				Events
			</NavLink>
			<NavLink
				to="/admin/blog"
				className={({ isActive }) => {
					return isActive
						? "flex flex-row gap-3 items-center rounded-xl p-2 text-white text-lg primary-gradient"
						: "flex flex-row gap-3 items-center rounded-xl p-2 text-white text-lg";
				}}
			>
				<BiNotepad />
				Blog Posts
			</NavLink>
			<NavLink
				to="/admin/educational"
				className={({ isActive }) => {
					return isActive
						? "flex flex-row gap-3 items-center rounded-xl p-2 text-white text-lg primary-gradient"
						: "flex flex-row gap-3 items-center rounded-xl p-2 text-white text-lg";
				}}
			>
				<BiNotepad />
				Educational Content
			</NavLink>
			<NavLink
				to="/admin/portfolio"
				className={({ isActive }) => {
					return isActive
						? "flex flex-row gap-3 items-center rounded-xl p-2 text-white text-lg primary-gradient"
						: "flex flex-row gap-3 items-center rounded-xl p-2 text-white text-lg";
				}}
			>
				<BsFileSpreadsheet />
				Portfolio
			</NavLink>
			<NavLink
				to="/admin/hackathon"
				className={({ isActive }) => {
					return isActive
						? "flex flex-row gap-3 items-center rounded-xl p-2 text-white text-lg primary-gradient"
						: "flex flex-row gap-3 items-center rounded-xl p-2 text-white text-lg";
				}}
			>
				<FaLaptop />
				Hackathon Registrations
			</NavLink>

			<NavLink
				to="/admin/manage-hackathons"
				className={({ isActive }) => {
					return isActive
						? "flex flex-row gap-3 items-center rounded-xl p-2 text-white text-lg primary-gradient"
						: "flex flex-row gap-3 items-center rounded-xl p-2 text-white text-lg";
				}}
			>
				<FaSpaceShuttle />
				Manage Hackathons
			</NavLink>
			<NavLink
				to="/"
				className={({ isActive }) => {
					return isActive
						? "flex flex-row gap-3 items-center rounded-xl p-2 text-white text-lg primary-gradient"
						: "flex flex-row gap-3 items-center rounded-xl p-2 text-red-500 text-lg active:bg-red-800 active:text-white mt-5";
				}}
			>
				<FaArrowLeft />
				Log Out
			</NavLink>
		</div>
	);
};

export default Sidebar;

import React from "react";
import { BiHeart } from "react-icons/bi";

const BlogCard = ({ img, title, text, likes, date }) => {
	return (
		<div className="relative">
			{/* gradient styling. Styling present in index.css */}
			<div className="border-gradient-styling"></div>
			{/* card-gradient class in index.css file */}
			<div className="card-gradient bg-black p-4 rounded-xl flex gap-2 flex-col sm:flex-row md:w-[700px]">
				<div className="flex-1">
					<img src={img} alt="" />
				</div>
				<div className="flex justify-between flex-col flex-1">
					<div className="flex flex-row text-sm justify-between p-1 -r-3">
						<div>The dapps over apps team</div>
						<div>
							<span className="h-2 w-2 rounded-full"></span>{" "}
							{date}
						</div>
					</div>

					<h4 className="font-semibold text-lg">{title}</h4>
					<div className="text-sm">
						{text.length > 100 ? (
							<div>
								{text.slice(0, 200)}...
								<span className="text-primary px-2">
									Read More
								</span>
							</div>
						) : (
							text
						)}
					</div>
					<div className="flex flex-row items-center gap-2">
						<BiHeart /> {likes} likes
					</div>
				</div>
			</div>
		</div>
	);
};

export default BlogCard;

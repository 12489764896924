import React, { useEffect } from "react";
import Frame_2 from "../assets/Home_Assets/Frame 41_2.png";
import Frame_3 from "../assets/Home_Assets/Frame 41_3.png";
import Component_5 from "../assets/Home_Assets/Component 5.png";
import style from "../styles/Home.module.css";
import Button from "../components/Button/Button";
import Page from "../components/Page";
import { motion } from "framer-motion";

const Home = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Page className={style.container}>
			<div className={style.pink_blur}></div>
			<div className={style.purple_blur}></div>
			<div className={style.first_section}>
				<motion.img src={Frame_2} alt="" className={style.hero_img} />
				<div className={style.ctn_text}>
					<h1>A Home for all techies and creative builders</h1>
					<p>
						Encouraging builder and traditional developers explore
						the world of web 3.0 and "get smart" with blockchain
						infrastructures
					</p>
					<a href="https://t.co/UboKhtA0TG">
						<Button
							text="Join the Community"
							bgColor="linear-gradient(90deg, var(--secondary-bg-color) 0%, var(--asset-bg-color) 100%)"
							className={style.ctab}
						/>
					</a>
				</div>
				<img src={Frame_3} alt="" className={style.hero_img} />
			</div>

			<div className={style.second_section}>
				<p>Partners</p>
				<div>
					<img src={Component_5} alt="" />
					<img src={Component_5} alt="" />
					<img src={Component_5} alt="" />
					<img src={Component_5} alt="" />
					<img src={Component_5} alt="" />
				</div>
			</div>

			<div className={style.third_section}>
				<h1>Benefits of dApps over apps</h1>
				<div className={style.third__section__flex}>
					<div>
						<h2>Education</h2>
						<p>
							Educational content available at all times to help
							you get better at using decentralized applications
							to aid your growth.
						</p>
						<span></span>
					</div>
					<div>
						<h2>Blockchain Events</h2>
						<p>
							Blockchain events bring together a diverse community
							of professionals, providing networking opportunities
							to expand your reach and influence.
						</p>
						<span></span>
					</div>
					<div>
						<h2>Tutorial Bootcamps</h2>
						<p>
							Bootcamps empower participants with knowledge,
							skills and tools to thrive in their field,
							postioning you as a champion of personal and
							professional growth.
						</p>
						<span></span>
					</div>
				</div>
			</div>

			<div className={style.fourth_section + " text-white"}>
				<h1>dApps over apps classes</h1>
				<p>A transformative journey into the world of blockchain technology. Whether you're a curious beginner or a tech enthusiast looking to dive deeper into the blockchain ecosystem and tech at large, this class is designed for you</p>
				<a href="https://tr.ee/rv6_O0pMjR" target="blank" className={style.linkBtn}>
					Get Started
				</a>
			</div>
		</Page>
	);
};

export default Home;

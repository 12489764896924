// import { NavLink } from "react-router-dom";
// import HackathonImg from "../assets/HackathonImg.png";
import Footer from "../components/Footer/Footer";
import Page from "../components/Page";
import HackathonPageCard from "../components/HackathonPageCard";
import OpenWebImg from "../assets/Hackathon_Assets/OpenWebImg.png";

const HackathonPage = () => {
	return (
		<Page className="text-white">
			<h1 className="text-3xl font-bold rounded-2xl primary-gradient p-2 px-5 w-fit mt-14 animate-bounce">
				Open Hackathons
			</h1>

			<HackathonPageCard
				title={"Open Web Tour"}
				text1={
					<div>
						Showcase your innovation using Arbitrum Stylus and SDK.
						Win <span className="text-xl font-bold">#3 Million</span> and access to a global network to scale
						your products.
					</div>
				}
				text2={"Register now and Start building!"}
				date={"Now - September 15th, 2024"}
				link={"/login"}
				image={OpenWebImg}
			>
			</HackathonPageCard>

			{/* <div className="flex lg:flex-row flex-col gap-4 p-4 lg:p-8 items-center justify-between">
				<div className="flex-1 bg-grayish p-8 rounded-xl">
					<div className="text-primary py-3">
						June 3rd - July 24th 2024
					</div>
					<h1 className="text-4xl font-bold py-4">
						Open Web Tour{" "}
						<span className="text-primary font-extrabold">
							Hackathon
						</span>{" "}
						Registration
					</h1>
					<div className="pt-3 pb-1 text-lg leading-5">
						Register for the Open Web Tour Hackathon and stand a
						chance to win a hackathon bounty of $2,000.
					</div>
					<div className="pb-7 text-lg leading-5">
						Open Web Tour is where you Learn, Earn and Build on the
						Near Blockchain using Rust programming language
					</div>
					<NavLink
						to="/login"
						className="p-2 primary-gradient rounded px-5 hover:animate-pulse"
					>
						Register Now
					</NavLink>
				</div>
				<div className="flex-1 py-5">
					<img src={HackathonImg} alt="" className="rounded-lg" />
				</div>
			</div> */}

			<Footer />
		</Page>
	);
};

export default HackathonPage;
